<template>
    <ul :class="{ 'menu' : !child, 'sub-menu' : child }">
        <li v-on:click="toggleSubMenu(index)" v-for="(item, index) in currentMenu" :key="index" :class="[ item.children ? 'menu-item-has-children' : '', item.open ? 'active' : '', item.current ? 'menu-item-current' : '', 'menu-item', ...item.classes ]">
            <a :href="item.url">{{ item.link_text }}</a>
            <tree v-if="item.children" :menu="item.children" :child="true"/>
        </li>
    </ul>
</template>

<script>
    export default {
        name : 'tree',

        props: {
            'menu': null,
            'child' : false,
        },

        data() {
            return {
                open : false,
                currentMenu : this.menu
            };
        },

        methods: {
            toggleSubMenu(index) {
                let value = Object.assign({}, this.currentMenu[index]);
                value.open = !value.open;
                this.$set(this.currentMenu, index, value);
            }
        },
    }
</script>