var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { class: { menu: !_vm.child, "sub-menu": _vm.child } },
    _vm._l(_vm.currentMenu, function(item, index) {
      return _c(
        "li",
        {
          key: index,
          class: [
            item.children ? "menu-item-has-children" : "",
            item.open ? "active" : "",
            item.current ? "menu-item-current" : "",
            "menu-item"
          ].concat(item.classes),
          on: {
            click: function($event) {
              return _vm.toggleSubMenu(index)
            }
          }
        },
        [
          _c("a", { attrs: { href: item.url } }, [
            _vm._v(_vm._s(item.link_text))
          ]),
          _vm._v(" "),
          item.children
            ? _c("tree", { attrs: { menu: item.children, child: true } })
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }