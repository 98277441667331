<template>
    <header class="site-header">
        <div :class="['site-header', open ? 'nav-active' : '']" :style="{ height : (open ? (windowHeight + 'px') : 'auto') }">
            <div class="container container-large">
                <div class="site-header__main-inner">
                    <div class="site-header__logo-wrapper">
                        <a href="/">
                            <img :src="logo" alt="Replimune logo.">
                        </a>
                    </div>
                    <div :class="['site-header__nav-trigger-wrapper', open ? 'active' : '']" v-on:click="toggleMenu()">
                        <a href="#" :class="['site-header__nav-trigger', open ? 'active' : '']">
                            <span></span>
                            <span></span>
                            <span></span>
                        </a>
                    </div>
                    <div :class="['site-header__nav-wrap', open ? 'menu-active' : 'menu-closed']">
                        <nav class="nav-menu">
                            <tree :menu="getMenu()"/>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="mobile-footer d-lg-none">
                <tree :menu="menuFooter"/>
            </div>
        </div>
    </header>
</template>

<script>
    import Tree from "./Tree";
    import Headroom from 'headroom.js';

    export default {
        props: {
            'menu': null,
            'logo': String,
            'menuFooter': null,
        },

        data() {
            return {
                windowHeight : window.innerHeight,
                open : false,
                currentPath : window.location.pathname
            };
        },


        components: {
           Tree,    
        },

        methods: {
            parseData(data) {
                this.menu = JSON.parse(data);
            },
            toggleMenu() {
                document.body.classList.toggle('noscroll');
                this.open = !this.open;
            },
            resize() {
                this.windowHeight = window.innerHeight;
            },
            getMenu() {
                let menu = this.menu;

                menu.forEach(menu => {
                    let a = document.createElement('a');
                    a.href = menu.url;

                    if(a.pathname == this.currentPath){
                        menu.current = true;
                    }
                });

                return menu;
            }
        },

        mounted() {
            window.addEventListener('resize', this.resize);
            this.resize();

			let headroom  = new Headroom( this.$el );
			headroom.init();
        }

    }
</script>