var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "site-header" }, [
    _c(
      "div",
      {
        class: ["site-header", _vm.open ? "nav-active" : ""],
        style: { height: _vm.open ? _vm.windowHeight + "px" : "auto" }
      },
      [
        _c("div", { staticClass: "container container-large" }, [
          _c("div", { staticClass: "site-header__main-inner" }, [
            _c("div", { staticClass: "site-header__logo-wrapper" }, [
              _c("a", { attrs: { href: "/" } }, [
                _c("img", { attrs: { src: _vm.logo, alt: "Replimune logo." } })
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "site-header__nav-trigger-wrapper",
                  _vm.open ? "active" : ""
                ],
                on: {
                  click: function($event) {
                    return _vm.toggleMenu()
                  }
                }
              },
              [
                _c(
                  "a",
                  {
                    class: [
                      "site-header__nav-trigger",
                      _vm.open ? "active" : ""
                    ],
                    attrs: { href: "#" }
                  },
                  [_c("span"), _vm._v(" "), _c("span"), _vm._v(" "), _c("span")]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "site-header__nav-wrap",
                  _vm.open ? "menu-active" : "menu-closed"
                ]
              },
              [
                _c(
                  "nav",
                  { staticClass: "nav-menu" },
                  [_c("tree", { attrs: { menu: _vm.getMenu() } })],
                  1
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mobile-footer d-lg-none" },
          [_c("tree", { attrs: { menu: _vm.menuFooter } })],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }